.insertConfiguration {
    margin: 0px 0px 0px 0px;
    height: 2px;
}

.insertConfigurationButton {
    display: none;
}

.insertConfiguration:hover {
    height: 24px;
}

.insertConfiguration:hover .insertConfigurationButton {
    margin: 0px auto;
    padding: 3px 7px;
    width: 131px;
    height: 20px;
    background-color: #37469c;
    border-radius: 2px;
    display: block;
    border: none;
    font-size: 12px;
    font-weight: 400;
    position: relative;
    z-index: 2;
}

.insertConfiguration:hover .line {
    height: 2px;
    background-color: #37469c;
    z-index: 1;
    position: relative;
    top: 13px;
    margin-right: 8px;
}

.insertConfigurationButton > span {
    margin: 0;
    text-align: center;
}
