

.autocomplete .autocomplete__single-value {
    color: var(--White);
}

.autocomplete .autocomplete__control,
.autocomplete .autocomplete__input,
.autocomplete .autocomplete__menu,
.autocomplete .autocomplete__indicator,
.autocomplete .autocomplete__value-container div:not(.autocomplete__single-value) {
    margin: 0px;
    padding: 0px;
}

.autocomplete .autocomplete__control {
    min-height: 30px;
    background-color: #1a2f6f;
    border-color: #1a2f6f;
    color: var(--White);
}

.autocomplete .autocomplete__input {
    color: var(--White);
    height: 26px;
}

.autocomplete .autocomplete__input input {
    height: 26px;
}
.autocomplete .autocomplete__menu-list {
    background-color: #333333;
    border-color: #1a2f6f;
    color: var(--White);
}

.autocomplete .autocomplete__option--is-focused {
    color: var(--Black);
}

.config-select .config-select__indicator-separator {
    display: none;
}

.autocomplete .autocomplete__indicator-separator {
    display: none;
}
