@import '../../Core';
$body-bg: #fefefe;
$navbar-height: 40px;
$navbar-border-radius: 0px;
$navbar-default-bg: darken($primaryColor, 1%);
$navbar-default-border: none;
$navbar-default-link-color: white;
$navbar-default-link-disabled-bg: darken($primaryColor, 1%);
$navbar-default-brand-hover-color: white;
$dropdown-border: none;
$dropdown-fallback-border: none;
$navbar-default-link-hover-bg: darken($primaryColor, 1%);
$navbar-default-link-active-bg: darken($primaryColor, 1%);
$navbar-default-link-hover-color: white;
$navbar-inverse-link-active-color: white;
$navbar-default-link-active-color: white;
$panel-primary-border: $secondaryColor;
$panel-primary-heading-bg: $secondaryColor;
$panel-default-border: lighten($primaryColor, 50%);
$panel-default-heading-bg: lighten($primaryColor, 50%);

$icon-font-path: '~bootstrap-sass/assets/fonts/bootstrap/';
@import '~bootstrap-sass/assets/stylesheets/bootstrap';
