#config-list-container {
    color: var(--White);
    width: 540px;
    font-family: Roboto, Sans-serif, Monospace;
    font-weight: 600;
    background-color: var(--Blue2);
    box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.5);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.config-header-container {
    width: 100%;
    padding: 0 1.14em;
    background-color: var(--Blue3);
    margin-bottom: 8px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.config-header-title {
    width: auto;
    font-size: 20px;
    font-weight: 500;
    margin-left: 18px;
}

.config-header-bottom{
    width: 100%;
    margin: 0 0 10px 18px;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

#config-list-container .title {
    font-size: 1.2em;
    font-weight: bold;
    color: var(--White);
}

#config-list-container .table-header {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
    text-transform: uppercase;
    grid-column: span 2;
    grid-auto-rows: min-content;
    align-self: end;
}

#config-list-container .table-header label{
    font-size: 12px;
    margin-left: 10px;
}

#configs-list {
    overflow-y: auto;
}

#config-list-cancel-save {
    outline: none;
}

.day-separator {
    position: relative;

  background-color: #4a5269;
  padding: 8px;
  font-weight: normal;
  line-height: 1.14;

  margin: 0 6px 0 16px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.day-separator .button-plan-menu{
    background: transparent;
    border: none;
}

.day-separator .menu-plan-container{
    width: 180px;
    background-color: #000000;

    position: absolute;
    bottom: -32px;
    right: 0;
}

.menu-plan-container .button-nm-sync {
    width: 100%;
    height: 32px;
    padding-left: 5px;
    background-color: #000000;
    border: none;
    justify-content: flex-start;
    gap: 8px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.nm-handle-container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.nm-progress-circle{
    display: flex;
    gap: 3px;
}


.button-nm-sync:hover{
    background-color: #4a5269;
    transition: all .2s;
}

.button-nm-sync:disabled{
    opacity: 50%;
}

.button-nm-sync:disabled:hover{
    background-color: #000000;
}

#save-changes-btn {
    height: 32px;
    width: 106px;
    color: var(--White);
    background-color: var(--Blue);
  }
