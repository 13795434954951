@import './Core';
@import './Leaflet.scss';

// Global CSS variables
:root {
  --height-menu-bar: 54px;
  --height-filter-bar: 40px;
  --height-operations-bar: 50px;
  --height-simulation-bar: 40px;
  --height-flight-list-header: 69px;
  --width-side-bar: 54px;
  
  --Black: #000000;
  --Blue1: #171a23;
  --Blue2: #1d212b;
  --Blue3: #242936;
  --Blue3Bis: #2e3546;
  --Blue4: #3b4459;
  --Blue5: #566180;
  --Blue6: #12131c;
  --Blue7: #848da6;
  --PrimaryBlue7: #d2d7e3;
  --PrimaryBlue8: #eceff6;
  --Blue: #2566e2;
  --Bluedarker: #1a4ba9;
  --BlueDisable: #225cbf;
  --Bluewriting: #6ca0ff;
  --Green: #00bfa5;
  --GreenLight: #559a92;
  --GreenDisable: #0c7568;
  --GreenDarker: #0d8274;
  --DarkGrey: #181919;
  --Grey1: #313232;
  --Grey2: #636465;
  --Grey3: #949597;
  --Grey4: #c6c7c9;
  --Grey5: #dee0e1;
  --Grey6: #eaecee;
  --Grey3Bis: #b7b7b7;
  --GreyDisable: #989aa0;
  --Orange: #fa6400;
  --Red: #ff3455;
  --Red2: #ff1744;
  --RedDarker: #c3253f;
  --White: #ffffff;
  --Yellow: #ffdd00;
  --Yellow2: #ffd625;
  --YellowDarker: #edbd00;

  --text-color-dark-background-white: #ffffffff;
}

/* bug: https://github.com/facebook/create-react-app/issues/11771 */
body > iframe[style*="2147483647"]:not([id="webpack-dev-server-client-overlay"]) { display: none; }

#root,
body,
html {
  margin: 0;
  height: 100%;
  width: 100%;
  font-size: $font-size-base;
  overflow: hidden;
}

/* bug: https://github.com/facebook/create-react-app/issues/11771 */
body > iframe[style*="2147483647"]:not([id="webpack-dev-server-client-overlay"]) { display: none; }

div,
button {
  outline: none;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
}

::-webkit-scrollbar-thumb {
    background-color: #9e9e9e;
    outline: 0px;
 }

/* ERROR MESSAGES */
input.invalid {
  border: 1px solid yellow !important;
}

input.invalid-red {
  border: 1px solid red;
}

input[type=text].invalid-red,
input[type=number].invalid-red {
  border: 1px solid red;
}

.check-message {
  font-size: 12px;
  font-style: italic;
  color: yellow;
}

.error-color {
  color: yellow;
}

 /* BUTTONS */
.button {
    float: right;
    position: relative;
    height: 32px;
    border-radius: 2px;
    border: solid 1px rgba(255, 255, 255, 0.31);
    font-family: Roboto, Sans-serif, Monospace;
    font-size: 12px;
    font-weight: 400;
    margin: 0px 3px;
    vertical-align: middle;
}

/* GLOBAL BUTTON STYLES */
.cancel { background-color: #4a4a4a; }
.cancel:hover { background-color: #302e2e; }

.save { background-color: #304ffd; }
.save:hover { background-color: #0e2ab5; }

.ok { background-color: #0a8c6f; }
.ok:hover { background-color: #085644; }
.ok:disabled {
    background-color: #0a8c6f;
    cursor: not-allowed;
}

.no-bg-button {
  background-color: transparent;
  border: 0;
}

/* Search Bar */
.search-container {
  background: var(--Blue3Bis);
  font-size: 14px;
  display: inline-flex;
  border-radius: 2px;
  overflow: auto;
  text-align: left;
  height: 34px;
  color: var(--White);
}

.search-container .searchIcon {
  margin-right: 10px;
  line-height: 1.3rem;
  font-size: 18px;
  cursor: inherit;
}

.search-container .searchBox {
  padding: 7px 0px 7px 10px;
  flex: 1;
  height: 100%;
  margin: 0px;
  line-height: 1.2em;
  background: none;
  border-style: none;
  width: 100px;
}

.search-container input:disabled {
  cursor: not-allowed;
}

.ActionButton {
  float: right;
  background-color: transparent;
  border: 0;
}