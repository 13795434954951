.multi-cta-handle-container{
  height: 24px;
  overflow: hidden;
  border-radius: 2px;

  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.multi-cta-handle-container > button{
  border-radius: 0;
  height: 100%;
  min-width: 87px;
}

.multi-cta-handle-container > button:hover{
  background-color: #2566e2;
}

.multi-cta-handle-button.inactive {
  background-color: var(--Blue3Bis);
}

.multi-cta-handle-button.inactive:hover{
  background-color: var(--Blue3Bis);
}